<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <form ref="form">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <div
            class="card card-custom card-sticky gutter-b"
            id="kt_page_sticky_card"
          >
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Add New Page
                  <i class="mr-2"></i>
                  <small class=""></small>
                </h3>
              </div>
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <button @click.prevent="submit" class="">Generate</button>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
          <div class="card card-custom gutter-b">1</div>

          <div class="card card-custom gutter-b">2</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'NewPage',
  data() {
    return {
      page: {
        title: 'Naslovnica',
        slug: '/',
        configuration: {
          section: [
            {
              name: 'Main Slider',
              slug: 'main-slider',
              payload: {
                title:
                  '<strong>Zaštitite kožu.</strong></br> Brinite o planetu.',
                subtitle: 'Eucerin sun zaštita od sunca'
              }
            },
            {
              name: 'Icons',
              slug: 'icons',
              payload: {
                title: 'Baš ono što ti treba',
                subtitle: 'Najpopularnije kategorije'
              }
            },
            {
              name: 'Products Slider',
              slug: 'products-slider',
              payload: {
                title: 'Drugi su kupili navedene proizvode',
                subtitle: 'Najprodavaniji artikli',
                entities: []
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Pages', route: '/pages/list' },
      { title: 'Add New' }
    ])
  },
  methods: {
    async submit() {
      // console.log(this.page)
      this.postPage(this.page)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Page success added'
          })
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Page error'
          })
        })
    },
    async postPage(entity) {
      return await ApiService.post('/pages', entity)
    }
  }
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 400px;
}
</style>
